<template>
  <div v-if="isPopupDelete" class="absolute top-0 w-[100%] flex justify-center bg-[#00000066] h-full items-center">
    <div>
      <div  ref="popup" class="!p-4 bg-ems-gray700">
        <p class="text-center mb-10 text-white">
          {{ t('coordinator.del_messages') }}
        </p>
        <div class="flex items-center justify-center gap-[30px]">
          <clip-button type="primary" @click="emitConfirm">{{
            $t('common.accept')
          }}</clip-button>
          <clip-button type="dashed" @click="emitCancel">{{
            $t('common.cancel_1')
          }}</clip-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import VueTypes from 'vue-types';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const emit = defineEmits(['handleConfirm', 'handleCancel']);
const props = defineProps({
  isPopupDelete: VueTypes.bool.def(),
});
const emitConfirm = () => {
  emit('handleConfirm');
};

const emitCancel = () => {
  emit('handleCancel');
};
</script>

<style>

</style>
