<template>
  <div
    class="h-full overflow-auto hidden-scroll"
    :style="{ height: `${windowHeight - 280}px` }"
  >
    <div v-for="(item, index) in listParticipants" :key="index">
      <div
        @click="handleSelect(item)"
        @mouseenter="hoveredItem = item.status.userId"
        @mouseleave="hoveredItem = ''"
        :class="`w-full h-[96px]  mt-[11px] ${
          hoveredItem === item.status.userId ? 'bg-ems-gray700' : ''
        } flex items-center px-[16px] space-x-[23px] rounded-lg relative cursor-pointer justify-between`"
      >
        <div class="flex items-center space-x-[23px]">
          <div
            class="rounded-full w-[64px] h-[64px] bg-white flex justify-center items-center relative flex-shrink-0"
          >
            <div
              class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
            >
              <img alt="avatar" :src="item.avatar" class="w-ful h-auto" />
            </div>
            <div
              v-if="item.status.online"
              class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
            ></div>
          </div>
          <div class="space-y-[8px]">
            <p class="text-white font-semibold text-[16px]">
              {{ item.fullName }}
            </p>
            <p
              :class="`${
                item.role === 'Admin'
                  ? 'text-ems-boTro4_600'
                  : 'text-ems-gray200'
              } font-normal text-[14px] truncate max-w-[250px]`"
            >
              {{ t('coordinator.member') }}
            </p>
          </div>
        </div>
        <div
          v-if="hoveredItem === item.status.userId"
          class="flex items-center justify-center bg-ems-main2 w-[40px] h-[40px] rounded-full"
        >
          <img
            alt="add"
            src="@/static/img/icon/mic-open.svg"
            class="h-[24px] cursor-pointer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { TAB_COORDINATION, TAB_WALKIE,PEER_TYPE } from '@/util/common-constant.js';
import { useWindowHeight } from '@/util/common-utils';
const { windowHeight } = useWindowHeight();
const { t } = useI18n();
const { state, dispatch } = useStore();
const nameSelect = ref('');
const hoveredItem = ref('');
const router = useRouter();
const route = useRoute();
const listParticipants = computed(
  () => state.coordinator.listParticipants || ''
);
const handleSelect = (item) => {
  let query = {
    id: item.status.userId,
    type: PEER_TYPE.USER,
    tab_list: TAB_COORDINATION.CONTACT,
    tab_walkie:TAB_WALKIE.WALKIE,
  };
  dispatch('coordinator/handleSetParams', query);
  dispatch('coordinator/getDialogsId', item.userId).then((dialogId) => {
    const isChated = !!dialogId;
    dispatch('coordinator/getMessagesByDialogs', {
      dialogId: dialogId,
      dialogType: 1,
      isChated: isChated,
    });
  });
  let value = {
    otherInfo: {
      peerAvatarUrl: item.avatar,
      peerId: item.status.userId,
      peerName: item.fullName,
      peerType: PEER_TYPE.USER,
      status: item.status.online,
      like: item.like,
    },
    tab: TAB_COORDINATION.CONTACT,
  };
  dispatch('coordinator/selectValue', value);
  dispatch('coordinator/getListStatus', item.userId);
  dispatch('coordinator/viewMember', TAB_WALKIE.DEFAULT);
};
</script>

<style>

</style>
