<template>
  <div id="map"></div>
</template>
<script setup>
import goongjs from '@goongmaps/goong-js';
import goongClient from '@goongmaps/goong-sdk';
import polyline from '@mapbox/polyline';
import { onMounted } from 'vue';
import { ref, defineEmits } from 'vue';
import { MARKER_TYPE } from '@/util/common-constant.js';

const goongDirections = require('@goongmaps/goong-sdk/services/directions');
goongjs.accessToken = window.VUE_APP_API_GOONG_MAP_TOKEN;
const emits = defineEmits(['click-marker']);
const props = defineProps({
  center: {
    type: Object,
    default: () => {
      return { lat: 21.051528423347538, lng: 105.80015809649605 };
    },
  },
  markers: {
    type: Array,
    default: () => [],
  },
  directions: {
    type: Array,
  },
});
const iconType = {};
iconType[MARKER_TYPE.CAMERA_SELECTED] = {
  icon: require('@/static/img/map/camera.png'),
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[42px] w-[42px] bg-ems-main1 !opacity-25 rounded-full',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.CAMERA_DEFAULT] = {
  icon: require('@/static/img/map/camera-default.png'),
  classAnimate: '',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.DEFAULT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.DO] = {
  icon: require('@/static/img/map/do.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.VANG] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.VANG_SELECTED] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro3_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.CURRENT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-[-15px] left-[-15px] absolute inline-flex h-[60px] w-[60px]  bg-[#3A5EF6] !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.TRINH_SAT] = {
  icon: require('@/static/img/map/scout.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro4_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.LOCATION] = {
  icon: require('@/static/img/map/location.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
let map = null;
let markers = [];
onMounted(() => {
  map = new goongjs.Map({
    container: 'map',
    style: 'https://tiles.goong.io/assets/goong_map_web.json', // stylesheet location
    center: [props.center.lng, props.center.lat], // starting position [lng, lat]
    zoom: 12, // starting zoom
    maxZoom: 16,
    trackResize: true,
  });
  map.addControl(new goongjs.NavigationControl());
  props.markers.forEach((e) => {
    let mk = addMarker(e);
    markers.push(mk);
  });
  if (
    props.directions &&
    props.directions.origin &&
    props.directions.destination
  ) {
    calculateAndDisplayRoute();
  }
});

const addMarker = (mk) => {
  // create DOM element for the marker
  let el = buildContent(mk);
  // create the popup
  let popup = new goongjs.Popup({ offset: 20, maxWidth: '300px' }).setHTML(
    mk.detailInfo
  );
  let marker = new goongjs.Marker(el).setLngLat([mk.lng, mk.lat]);
  marker.addTo(map);
  marker.setPopup(popup);
  return marker;
};
const removeAllMarker = () => {
  for (let marker of markers) {
    marker.remove();
  }
};

function buildContent(property) {
  const content = document.createElement('div');
  content.classList = `marker-goong group absolute`;
  content.id = `marker-goong-${property.id}`;
  if (property.infoWindow) {
    content.innerHTML = `
    <div class="relative icon-marker block h-[30px] w-[30px]">
        <span class="${iconType[property.type].classAnimate}"></span>
        <img src="${iconType[property.type].icon}" class="absolute z-1"/>
    </div>
    <div class="details absolute bottom-[-70px] flex group-hover:opacity-[1] opacity-[0]  flex-col !w-[max-content] " >
        <div class="hidden group-hover:flex">${property.infoWindow}</div>
        <div class="w-[20px] h-[20px] bg-[#E21A3D] group-hover:block hidden absolute top-[-8px] after-detail" ></div>
    </div>
    `;
  } else {
    content.innerHTML = `
        <div class="relative icon-marker block h-[30px] w-[30px]">
            <span class="${iconType[property.type].classAnimate}"></span>
            <img src="${iconType[property.type].icon}" class="absolute z-1"/>
        </div>
      `;
  }
  return content;
}

const setMarker = (list) => {
  removeAllMarker();
  for (let i in list) {
    let mk = addMarker(list[i]);
    markers.push(mk);
  }
};
const moveToLocation = (lat, lng) => {
  map.flyTo({
    center: [lng, lat],
  });
};
const calculateAndDisplayRoute = () => {
  map.on('load', function () {
    const arrowSvg = require('@/static/img/map/arrow-right-blue.png'); //get the img path
    const img = new Image(12, 12); //create HTMLElement
    img.src = arrowSvg; //set HTMLELement img src
    img.onload = () => map.addImage('arrow-right', img); //when img is loaded, add it to the map
    // Initialize goongClient with an API KEY
    const baseClient = goongClient({
      accessToken: window.VUE_APP_API_GOONG_MAP_API_KEY,
    });
    const directionService = goongDirections(baseClient);

    directionService
      .getDirections({
        origin: props.directions.origin,
        destination: props.directions.destination,
        vehicle: props.directions.vehicle,
      })
      .send()
      .then(function (response) {
        let directions = response.body;
        let route = directions.routes[0];

        let geometry_string = route.overview_polyline.points;
        let geoJSON = polyline.toGeoJSON(geometry_string);

        map.addSource('route', {
          type: 'geojson',
          data: geoJSON,
        });
        // Add route layer below symbol layers
        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3A5EF6',
            'line-width': 4,
          },
        });
        map.addLayer({
          id: 'arrow-icon',
          type: 'symbol',
          source: 'route',
          paint: {},
          layout: {
            'symbol-placement': 'line',
            'icon-image': 'arrow-right',
            'symbol-avoid-edges': true,
          },
        });
      });
  });
};
const resize = () => {
  map.resize();
};

defineExpose({
  moveToLocation,
  setMarker,
  calculateAndDisplayRoute,
  resize,
});
</script>

<style lang="scss">
#map {
  height: 100%;
}
#map .mapboxgl-popup-content {
  background-color: #171717;
  padding: 16px !important;
}
#map .mapboxgl-popup-content .mapboxgl-popup-close-button {
  right: 4px;
  top: 4px;
  color: #fff;
  font-size: 20px;
}
#map .mapboxgl-popup-content .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  grid-gap: 22px;
  gap: 22px;
}
#map .mapboxgl-popup-tip {
  border-top-color: #171717;
}
#map .marker-goong {
  align-items: flex-end;
  background-color: transparent;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  transition: all 0.2s ease-out;
  width: 30px;
}
#map .marker-goong .details {
  background-color: #e21a3d;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  width: auto;
  align-items: center;
  width: fit-content;
  flex: 1;
  transition: all 0.3s ease-out;
}
#map .marker-goong .details .after-detail {
  transform: rotate(45deg);
}
</style>
