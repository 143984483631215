<template>
  <div :class="`custom-tab  bg-ems-gray800 h-full ${isCollapse?'w-[100px]':'w-full'} overflow-hidden`">
    <div v-if="isCollapse" class="flex justify-center items-center pt-[20px]">
      <img src="@/static/img/icon/nav-expand.png" class="cursor-pointer" @click="isCollapse=false"/>
    </div>
    <div v-if="!isCollapse" class="flex justify-between items-center p-[20px]">
      <p class="text-ems-gray200 text-lg uppercase">{{ t('coordinator.list_coordinator') }}</p>
      <img src="@/static/img/icon/nav-collapse.png" class="cursor-pointer" @click="isCollapse=true"/>
    </div>
    <a-tabs
         v-if="!isCollapse" v-model:activeKey="activeTab" centered @change="handleChangeTab"
      >
        <a-tab-pane v-for="item in tabs" :key="item.key">
          <template #tab>
            <span>{{ item.tabName }} </span>
          </template>
        </a-tab-pane>
      </a-tabs>
      <Suspense>
        <template #default>
          <component :is="component" />
        </template>
        <template #fallback>
          <div class="spin-custom">
            <cds-loading />
          </div>
        </template>
      </Suspense>
  </div>
</template>

<script setup>
import { ref, computed,watch,defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter,useRoute } from 'vue-router';
import { TAB_COORDINATION } from '@/util/common-constant.js';
import { useStore } from 'vuex';
const route = useRoute();
const component = ref('');
const { t } = useI18n();
const activeTab = ref( TAB_COORDINATION.COORDINATION);
const isCollapse = ref(false)
const router = useRouter();
const {  dispatch } = useStore();
const TabCoordinator = defineAsyncComponent(() => import('./tab/tab-coordinator.vue'));
const TabPhoneBook = defineAsyncComponent(() => import('./tab/tab-phone-book.vue'));
const TabChannel = defineAsyncComponent(() => import('./tab/tab-channel.vue'));
const tabs = [
  {
    key: TAB_COORDINATION.COORDINATION,
    tabName:t('coordinator.coordinator'),
    component: TabCoordinator,
  },
  {
    key: TAB_COORDINATION.CONTACT,
    tabName: t('coordinator.contact'),
    component: TabPhoneBook,
  },
  {
    key: TAB_COORDINATION.CHANNEL,
    tabName: t('coordinator.channel'),
    component: TabChannel,
  },
];
watch(
  () => route.query.tab_list,
  (newTab, oldTab) => {
    activeTab.value = newTab
  }
);
watch(
  () => route.query,
  () => {
    if (!route.query.tab_list) {
      activeTab.value = TAB_COORDINATION.COORDINATION;
    } else {
      activeTab.value = route.query.tab_list;
    }
    tabs.forEach((item) => {
      if (item.key === activeTab.value) {
        component.value = item.component;
      }
    });
  },
  {
    immediate: true,
    deep: true,
  }
);
const handleChangeTab = (value) => {
  let query = {
    tab_walkie:route.query.tab_walkie,
    tab_list: value,
  }
  dispatch('coordinator/handleSetParams', query);
};
</script>
<style lang="scss">
.custom-tab {
  transition: width 0.5s ease;
}
.custom-tab .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #434343;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-tab .ant-tabs-tab {
  width: 140px;
  display: flex;
  justify-content: center;
}
</style>
